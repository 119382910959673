// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-index-js": () => import("/opt/render/project/src/src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-404-index-mdx": () => import("/opt/render/project/src/src/pages/404/index.mdx" /* webpackChunkName: "component---src-pages-404-index-mdx" */),
  "component---src-pages-about-index-mdx": () => import("/opt/render/project/src/src/pages/about/index.mdx" /* webpackChunkName: "component---src-pages-about-index-mdx" */),
  "component---src-pages-analyze-and-fix-structured-data-index-mdx": () => import("/opt/render/project/src/src/pages/analyze-and-fix-structured-data/index.mdx" /* webpackChunkName: "component---src-pages-analyze-and-fix-structured-data-index-mdx" */),
  "component---src-pages-asynchronous-programming-with-es-6-generators-promises-and-npm-co-index-mdx": () => import("/opt/render/project/src/src/pages/asynchronous-programming-with-ES6-generators-promises-and-npm-co/index.mdx" /* webpackChunkName: "component---src-pages-asynchronous-programming-with-es-6-generators-promises-and-npm-co-index-mdx" */),
  "component---src-pages-attempt-to-run-desktop-ui-application-in-isolated-environment-on-windows-index-mdx": () => import("/opt/render/project/src/src/pages/attempt-to-run-desktop-ui-application-in-isolated-environment-on-windows/index.mdx" /* webpackChunkName: "component---src-pages-attempt-to-run-desktop-ui-application-in-isolated-environment-on-windows-index-mdx" */),
  "component---src-pages-creating-publishing-a-browser-extension-or-how-changing-a-stride-favicon-improved-my-performance-by-40-index-mdx": () => import("/opt/render/project/src/src/pages/Creating-publishing-a-browser-extension-or-How-changing-a-Stride-favicon-improved-my-performance-by-40/index.mdx" /* webpackChunkName: "component---src-pages-creating-publishing-a-browser-extension-or-how-changing-a-stride-favicon-improved-my-performance-by-40-index-mdx" */),
  "component---src-pages-contact-index-mdx": () => import("/opt/render/project/src/src/pages/contact/index.mdx" /* webpackChunkName: "component---src-pages-contact-index-mdx" */),
  "component---src-pages-css-organization-naming-conventions-and-safe-extend-without-preprocessors-index-mdx": () => import("/opt/render/project/src/src/pages/css-organization-naming-conventions-and-safe-extend-without-preprocessors/index.mdx" /* webpackChunkName: "component---src-pages-css-organization-naming-conventions-and-safe-extend-without-preprocessors-index-mdx" */),
  "component---src-pages-getting-started-with-reporting-api-network-error-logging-report-uri-index-mdx": () => import("/opt/render/project/src/src/pages/Getting-started-with-Reporting-API-Network-Error-Logging-Report-URI/index.mdx" /* webpackChunkName: "component---src-pages-getting-started-with-reporting-api-network-error-logging-report-uri-index-mdx" */),
  "component---src-pages-cyclomatic-complexity-refactoring-tips-index-mdx": () => import("/opt/render/project/src/src/pages/cyclomatic-complexity-refactoring-tips/index.mdx" /* webpackChunkName: "component---src-pages-cyclomatic-complexity-refactoring-tips-index-mdx" */),
  "component---src-pages-how-to-install-postgres-for-ubuntu-18-wsl-index-mdx": () => import("/opt/render/project/src/src/pages/how-to-install-postgres-for-ubuntu-18-wsl/index.mdx" /* webpackChunkName: "component---src-pages-how-to-install-postgres-for-ubuntu-18-wsl-index-mdx" */),
  "component---src-pages-css-color-scheme-and-iframes-lessons-learned-from-disqus-background-bug-index-mdx": () => import("/opt/render/project/src/src/pages/css-color-scheme-and-iframes-lessons-learned-from-disqus-background-bug/index.mdx" /* webpackChunkName: "component---src-pages-css-color-scheme-and-iframes-lessons-learned-from-disqus-background-bug-index-mdx" */),
  "component---src-pages-how-to-protect-privacy-in-chromium-based-browsers-index-mdx": () => import("/opt/render/project/src/src/pages/how-to-protect-privacy-in-chromium-based-browsers/index.mdx" /* webpackChunkName: "component---src-pages-how-to-protect-privacy-in-chromium-based-browsers-index-mdx" */),
  "component---src-pages-my-experience-using-wsl-windows-subsystem-for-linux-index-mdx": () => import("/opt/render/project/src/src/pages/my-experience-using-wsl-windows-subsystem-for-linux/index.mdx" /* webpackChunkName: "component---src-pages-my-experience-using-wsl-windows-subsystem-for-linux-index-mdx" */),
  "component---src-pages-manage-project-dependencies-with-simple-installer-index-mdx": () => import("/opt/render/project/src/src/pages/manage-project-dependencies-with-simple-installer/index.mdx" /* webpackChunkName: "component---src-pages-manage-project-dependencies-with-simple-installer-index-mdx" */),
  "component---src-pages-privacy-concern-for-extensions-that-rely-on-speechsynthesis-index-mdx": () => import("/opt/render/project/src/src/pages/privacy-concern-for-extensions-that-rely-on-speechsynthesis/index.mdx" /* webpackChunkName: "component---src-pages-privacy-concern-for-extensions-that-rely-on-speechsynthesis-index-mdx" */),
  "component---src-pages-projects-index-mdx": () => import("/opt/render/project/src/src/pages/projects/index.mdx" /* webpackChunkName: "component---src-pages-projects-index-mdx" */),
  "component---src-pages-recommended-index-mdx": () => import("/opt/render/project/src/src/pages/recommended/index.mdx" /* webpackChunkName: "component---src-pages-recommended-index-mdx" */),
  "component---src-pages-relative-urls-in-structured-data-index-mdx": () => import("/opt/render/project/src/src/pages/relative-urls-in-structured-data/index.mdx" /* webpackChunkName: "component---src-pages-relative-urls-in-structured-data-index-mdx" */),
  "component---src-pages-using-fiddler-inside-windows-sandbox-index-mdx": () => import("/opt/render/project/src/src/pages/using-fiddler-inside-windows-sandbox/index.mdx" /* webpackChunkName: "component---src-pages-using-fiddler-inside-windows-sandbox-index-mdx" */)
}

exports.data = () => import(/* webpackChunkName: "pages-manifest" */ "/opt/render/project/src/.cache/data.json")

