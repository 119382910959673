import seo from "../../../src/pages/analyze-and-fix-structured-data/lighthouse-seo.png";
import issues from "../../../src/pages/analyze-and-fix-structured-data/issues.png";
import lessIssues from "../../../src/pages/analyze-and-fix-structured-data/less-issues.png";
import React from 'react';
import { MDXTag } from '@mdx-js/tag';
export default {
  seo,
  issues,
  lessIssues,
  React,
  MDXTag
};