import imageWithValidatorDomain from "../../../src/pages/relative-urls-in-structured-data/path-has-validator-domain.png";
import imageWithCorrectDomain from "../../../src/pages/relative-urls-in-structured-data/path-has-correct-domain.png";
import imagePathError from "../../../src/pages/relative-urls-in-structured-data/image-path-error.png";
import Link from "../../../src/components/Link.js";
import React from 'react';
import { MDXTag } from '@mdx-js/tag';
export default {
  imageWithValidatorDomain,
  imageWithCorrectDomain,
  imagePathError,
  Link,
  React,
  MDXTag
};